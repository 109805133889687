import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import global from './modules/global'
import business from './modules/business'
import content from './modules/content'
import currentUser from './modules/currentUser'
import events from './modules/events'
import marketingSources from './modules/marketingSouces'
import pages from './modules/pages'
import participants from './modules/participants'
import reports from './modules/reports'
import sponsors from './modules/sponsors'
import supportMedia from './modules/supportMedia'
import users from './modules/users'
import messages from './modules/messages'
import surveys from './modules/surveys'
//import invoices from './modules/invoices'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['currentUser','business'] // Only save limited modules - avoid exceeded the quota err
})

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    global,
    business,
    content,
    currentUser,
    events,
    marketingSources,
    pages,
    participants,
    reports,
    sponsors,
    supportMedia,
    users,
    messages,
    surveys
  },
  plugins: [vuexLocal.plugin]
})
