<template>
    <div class="survey" v-if="responses.length > 0">
      <b-card
        :title="survey.title"
        class="mb-4"
      >
        <b-card-text>
          Please rate the following questions on a scale of 0 - 10.
        </b-card-text>
        <div v-for="(question, index) in survey.questions" class="mb-4" :key="index">
          <!-- response as range from 0 - 10 -->
          <b-form-group
            :id="`question-` + question.id"
            :label="question.label"
            label-class="font-weight-bold pt-2"
          >
            <b-input-group>
              <b-input-group-prepend>
                <b-button @click="responses[index].response = 0" :disabled="disabled">Reset</b-button>
              </b-input-group-prepend>
              <b-form-rating stars="10" v-model="responses[index].response" color="#ff8800" :disabled="disabled"></b-form-rating>
              <b-input-group-append>
                <b-input-group-text class="justify-content-center" style="min-width: 3em;">
                  {{ responses[index].response }}
                </b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <b-form-group
            :id="`comment-` + question.id"
          >
            <b-form-input v-model="responses[index].comment" placeholder="Comment (optional)" :disabled="disabled"></b-form-input>
          </b-form-group>
        </div>
        <div class="text-right"><b-button @click="submitResponse" :disabled="disabled">Submit Questionnaire</b-button></div>
      </b-card>
    </div>
</template>
<script>
// NPS Survey Feature
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Survey',
  props: {
    surveyId: {
      type: Number,
      required: true
    },
    user: {
      type: String,
      required: false
    },
    year: {
      type: [Number, String],
      required: true
    },
    month: {
      type: [Number, String],
      required: true
    },
    day: {
      type: [Number, String],
      required: false,
      default: null
    }
  },
  data: () => ({
    theSurvey: {
      title: null,
      questions: []
    },
    responses: [],
    disabled: false
  }),
  computed: {
    ...mapGetters(['survey','surveyResponses']),
    timeLine () {
      return this.day ? 'day' : 'month'
    }
  },
  methods: {
    ...mapActions(['getSurvey']),
    async fetchSurvey() {
      await this.getSurvey(this.surveyId).then(() => {})
      this.initResponses()
      this.checkForExistingResponse()
      this.theSurvey = this.survey
    },
    initResponses() {
      this.responses = []
      for (let i = 0; i < this.survey.questions.length; i++) {
        this.responses.push({
          question: this.survey.questions[i]['@id'],
          response: 0,
          comment: ''
        })
      }
    },
    async checkForExistingResponse() {
      this.disabled = false
      this.initResponses()
      let dateToStart = this.$moment(this.year + '-' + this.month + '-' + (this.day || 1))
      let dateToEnd = this.$moment(this.year + '-' + this.month + '-' + (this.day || dateToStart.daysInMonth()))

      await this.$store.dispatch('getSurveyResponses', {
        user: this.user || this.currentUser['@id'],
        survey: this.survey['@id'],
        'submittedOn[after]': this.$moment(dateToStart).startOf(this.timeLine).format(),
        'submittedOn[before]': this.$moment(dateToEnd).endOf(this.timeLine).format()
      }).then(() => {
        if (this.surveyResponses.length > 0) {
          this.disabled = true
          this.responses = this.surveyResponses[0].surveyQuestionResponses
        }
      })
    },
    async submitResponse() {
      let response = {
        user: this.currentUser['@id'],
        survey: this.survey['@id'],
        responses: this.responses
      }
      let surveyResponse = await this.$store.dispatch('postSurveyResponse', response)
      this.$store.dispatch('getSurveyResponses', {
        id: surveyResponse['@id'],
        user: this.currentUser['@id'],
        survey: this.survey['@id']
      })
    }
  },
  watch: {
    surveyId() {
      this.fetchSurvey()
    },
    user() {
      this.checkForExistingResponse()
    },
    year() {
      this.checkForExistingResponse()
    },
    month() {
      this.checkForExistingResponse()
    },
    day() {
      this.checkForExistingResponse()
    }
  },
  created() {
    this.fetchSurvey()
  }
}
</script>
