<template>
  <div ref="formBusiness" class="animated fadeIn">
    <b-tabs content-class="mb-4">
      <b-tab title="Franchise Data" :active="!iniSave">
        <b-form @submit.prevent="onSubmit" @reset.prevent="onReset" v-if="show" autocomplete="off">
          <b-card>
            <b-card-header>
              <i class="icon-location-pin mr-1"></i>{{ formLabel }} 
              <b-form-checkbox 
                v-model="businessForm.active"
                id="formfield-business-active"
                class="float-right"
              >Active</b-form-checkbox>
            </b-card-header>
            <b-card-body>
            
            <fieldset class="mb-4">
              <legend>Location</legend>
            <b-form-group id="formgroup-business-directors"
                          label="Director(s):"
                          label-for="formfield-business-directors">
              <v-select id="input-business-directors" v-model="selectedUsers" multiple :options="directorOptions" placeholder="Select director(s)" required></v-select>
            </b-form-group>

            <b-form-group v-if="selectedUsers.length > 1" id="formgroup-business-primaryContact"
                          label="Primary Contact:"
                          label-for="formfield-business-primaryContact">
              <b-form-radio-group 
                id="input-business-primaryContact" 
                v-model="businessForm.primaryContact">
                <span v-for="user in selectedUsers" :key="user.id"><b-form-radio :value="user.value">{{ user.label }}</b-form-radio></span>
              </b-form-radio-group>
            </b-form-group>
          
            <b-form-row>
              <b-col cols="12" md="4">
                <b-form-group id="formgroup-business-area_name"
                              label="Area Name:"
                              label-for="formfield-business-area_name">
                  <b-form-input id="input-business-area_name"
                                type="text"
                                v-model="businessForm.areaName"
                                required
                                placeholder="Enter area name">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="4">
                <b-form-group id="formgroup-business-uri_name"
                              label="URI:"
                              label-for="formfield-business-uri_name">
                  <b-form-input id="input-business-uri_name"
                                type="text"
                                v-model="businessForm.uriName"
                                required
                                placeholder="Enter URI">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="4">
                <b-form-group id="formgroup-business-email"
                              label="Email"
                              label-for="formfield-business-email">
                  <b-form-input id="input-business-email"
                                type="email"
                                v-model="businessForm.email"
                                required
                                placeholder="Enter email">
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>
            
            <b-form-group id="formgroup-business-aliases"
                          label="URI Aliases"
                          label-for="formfield-business-aliases">
              <b-form-tags
                input-id="formfield-business-aliases"
                v-model="uriAliasesUpdated"
                :tag-validator="uriAliasValidator"
                separator=" ,"
                placeholder="Add alias..."
              ></b-form-tags>

              <template #invalid-feedback>
                URI aliases must be at least 2 characters in length and all lower case.
              </template>

              <template #description>
                <div id="uriAliases-validation-help">
                  URI aliases must be at least 2 characters in length and all lower
                  case. Enter aliases separated by spaces, commas, or press enter.
                </div>
              </template>
            </b-form-group>
            
            <b-form-group id="formgroup-business-territories"
                          label="Territories"
                          label-for="formfield-business-territories"
                          :description="'Comma-separated list of ' + (businessId ? territoriesOriginalRaw.length : '') + ' territories'">
              <b-form-textarea id="input-business-territories"
                            v-model="territoriesUpdated"
                            placeholder="11111, 22222, 33333, etc...">
              </b-form-textarea>
            </b-form-group>
            </fieldset>
          
            <fieldset class="mb-4">
              <legend>Reporting</legend>
            <b-form-row>
              <b-col>
                <b-form-group id="formgroup-business-royaltyPercentage"
                              label="Royalty Percentage:"
                              label-for="formfield-business-royaltyPercentage"
                                description="use decimal format. E.g., .07">
                  <b-form-input id="input-business-royaltyPercentage"
                                type="number"
                                step="0.01"
                                v-model="businessForm.royaltyPercentage"
                                required>
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="formgroup-business-royaltyPinimum"
                              label="Minimum Royalty:"
                              label-for="formfield-business-royaltyPinimum">
                  <b-input-group prepend="$">
                    <b-form-input id="input-business-royaltyPinimum"
                                  type="number"
                                  step="0.01"
                                  v-model="businessForm.royaltyPinimum"
                                  required>
                    </b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-form-row>
            </fieldset>
            
            <fieldset>
              <legend>Mailing Address</legend>
            <b-form-row>
              <b-col>
                <b-form-group id="formgroup-business-address1"
                              label="Address:"
                              label-for="formfield-business-address1">
                  <b-form-input id="input-business-address1"
                                type="text"
                                v-model="businessForm.address1"
                                placeholder="123 fourth st">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="3">
                <b-form-group id="formgroup-business-address2"
                              label="Apt/Suite/PO Box:"
                              label-for="input-business-address2">
                  <b-form-input id="input-business-address2"
                    maxlength="10"
                                type="text"
                                v-model="businessForm.address2">
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col md="4" cols="12">
                <b-form-group id="formgroup-business-city"
                              label="City:"
                              label-for="formfield-business-city">
                  <b-form-input id="input-business-city"
                                type="text"
                                v-model="businessForm.city">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4" cols="8">
                <b-form-group id="formgroup-business-state"
                              label="State:"
                              label-for="formfield-business-state">
                  <b-form-select id="select-business-state"
                                :options="states"
                                v-model="businessForm.state">
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="4" cols="4">
                <b-form-group id="formgroup-business-zipCode"
                              label="Zip:"
                              label-for="formfield-business-zipCode">
                  <b-form-input id="input-business-zipCode"
                                type="number"
                                v-model="businessForm.zipCode"
                                required>
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>
            </fieldset>
            <div class="clearfix">
              <b-button type="reset" variant="danger">Reset</b-button>
              <b-button type="submit" variant="success" class="float-right">Submit</b-button>
            </div>
          </b-card-body>
          </b-card>
        </b-form>
      </b-tab>

      <b-tab title="Public Data" :disabled="!businessId" :active="iniSave">
        <!-- public data -->
        <BusinessPublicData :bid="businessId" />
      </b-tab>
    </b-tabs>

    <!-- status -->
    <b-modal ref="businessFormStatusModal" ok-only hide-header>
      <b-container fluid>
       {{ formStatus }}
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import BusinessPublicData from '@/components/BusinessPublicData'

const IRI_USERS = '/api/users/_id'

const FORM_DEFAULTS = {
  email: '',
  areaName: '',
  uriName: '',
  users: [],
  primaryContact: null,
  royaltyPercentage: '',
  royaltyPinimum: '',
  active: true,
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipCode: '',
  phone: '',
  social: {},
  sports: []
}

export default {
  name: 'BusinessForm',
  components: { vSelect, BusinessPublicData },
  data () {
    return {
      iniSave: false,
      businessForm: null,
      // users
      directorOptions: [],
      selectedUsers: [],
      // form
      businessId: null,
      show: true,
      formStatus: '',
      // territories
      territoriesOriginalRaw: [],
      territoriesOriginal: [],
      territoriesUpdated: '',
      uriAliases: [],
      uriAliasesUpdated: []
    }
  },
  computed: {
    ...mapGetters([
      'userList'
    ]),
    states () {
      return this.$store.getters.states
    },
    formLabel () {
      return (!this.businessId) ? 'Franchise Form' : this.businessForm.areaName
    }
  },
  methods: {
    async loadBusiness () {
      // let userIndex
      this.selectedUsers = []
      this.territoriesOriginalRaw =[]
      this.territoriesOriginal = []
      this.territoriesUpdated = ''
      await this.$store.dispatch('getBusiness', {id: this.businessId})
      let business = { ...this.$store.getters.business }
      this.businessForm.active = business.active
      this.businessForm.email = business.email
      this.businessForm.areaName = business.areaName
      this.businessForm.uriName = business.uriName
      this.businessForm.users = business.users
      this.businessForm.primaryContact = business.primaryContact['@id']
      this.businessForm.address1 = business.address1
      this.businessForm.address2 = business.address2
      this.businessForm.city = business.city
      this.businessForm.state = business.state
      this.businessForm.zipCode = business.zipCode
      this.businessForm.royaltyPercentage = business.royaltyPercentage
      this.businessForm.royaltyPinimum = business.royaltyMinimum
      this.businessForm.phone = business.phone
      this.businessForm.social = business.social
      this.businessForm.sports = business.sports

      // set user data
      for (let i1 in this.businessForm.users) {
        for (let i2 in this.directorOptions) {
          if (this.businessForm.users[i1] && this.businessForm.users[i1]['@id'] == this.directorOptions[i2].value) {
            this.selectedUsers.push(this.directorOptions[i2])
            // remove from users list because we will add it back when saving
            delete this.businessForm.users[i1]
          }
        }
      }

      // set coverage
      this.territoriesOriginalRaw = business.coverage
      this.territoriesOriginal = this.territoriesOriginalRaw.map((t) => t.postalCode)
      this.territoriesUpdated = this.territoriesOriginal.join(', ')

      // set URI aliases
      this.uriAliases = business.aliases
      this.uriAliasesUpdated = business.aliases.map((a) => a.uriName)
    },
    setUriName () {
      if (this.businessForm.uriName !== '') {
        return
      }
      let uriName = this.businessForm.areaName.toLowerCase()
      this.businessForm.uriName = uriName.trim().replace(/\s/g, '-')
    },
    userName (id) {
      for (let i in this.userList) {
        if (this.userList[i]['@id'] === id) {
          return this.userList[i].fullName
        }
      }
    },
    uriAliasValidator (value) {
      const allowed = /^[a-z0-9-_]+$/;
      return value === value.toLowerCase() && value.length > 1 && allowed.test(value)
    },
    async onSubmit () {
      this.formStatus = ''
      // set users
      let users = []
      for (let i in this.selectedUsers) {
        users.push(this.selectedUsers[i].value)
      }

      // ini post data
      let businessData = {
        active: this.businessForm.active,
        areaName: this.businessForm.areaName,
        uriName: this.businessForm.uriName,
        users,
        primaryContact: this.businessForm.primaryContact,
        email: this.businessForm.email,
        address1: this.businessForm.address1,
        address2: this.businessForm.address2,
        city: this.businessForm.city,
        state: this.businessForm.state,
        zipCode: this.businessForm.zipCode,
        royaltyPercentage: this.businessForm.royaltyPercentage,
        royaltyMinimum: this.businessForm.royaltyPinimum
      }

      try {
        if (this.businessId) {
          businessData.id = this.businessId
          await this.addTerritories()
          await this.removeTerritories()
          await this.addAlias()
          await this.removeAlias()
          await this.$store.dispatch('putBusiness', businessData)
          this.formStatus += `the business has been updated.`
          this.$refs.businessFormStatusModal.show()
          this.loadBusiness()
        } else {
          await this.$store.dispatch('postBusiness', businessData)
          let business = Object.assign({}, this.$store.getters.business)
          this.businessId = business.id
          await this.addTerritories()
          await this.removeTerritories()
          await this.addAlias()
          await this.removeAlias()
          this.formStatus += 'the business has been added. PLEASE COMPLETE THE PUBLIC DATA NEXT.'
          this.$refs.businessFormStatusModal.show()
          this.$router.push({ name: 'businessForm', query: { bid: this.businessId, view: 'public-data' } })
        }
      } catch (e) {
        this.formStatus = e
      }
    },
    async addAlias () {
      const promises = [] 
      let aliasesNew = this.uriAliasesUpdated
      // check for added alias
      for (let k in aliasesNew) {
        let exists = this.uriAliases.find((a) => a.uriName == aliasesNew[k])
        if (!exists) {
          // check if business name exists
          const uriNameExists = await this.$store.dispatch('getBusiness', {
            uriName: aliasesNew[k]
          })
          if (uriNameExists) {
            // give message that business name already exists
            this.$bvToast.toast(`The business name "${aliasesNew[k]}" already exists`, {
              title: 'Business name Exists',
              variant: 'danger',
              solid: true,
            })
          } else {
            // check if alias already exists
            const aliasExists = await this.$store.dispatch('getAliases', {
              uriName: aliasesNew[k]
            })
            if (aliasExists.length > 0) {
              // give message that alias already exists
              this.$bvToast.toast(`The alias "${aliasesNew[k]}" already exists`, {
                title: 'Alias Exists',
                variant: 'danger',
                solid: true,
              })
            } else {
              await this.$store.dispatch('postAlias', {
                uriName: aliasesNew[k],
                business: "/api/businesses/" + this.businessId
              })
            }
          }
        }
      }
      console.log('promises', promises)
      return await Promise.resolve('')
    },
    async removeAlias () {
      const promises = [] 
      let aliasesNew = this.uriAliasesUpdated
      // check for removed aliases
      for (let k1 in this.uriAliases) {
        if (!aliasesNew.includes(this.uriAliases[k1].uriName)) {
          // remove alias
          promises.push(this.$store.dispatch('deleteAlias', this.uriAliases[k1].id))
        }
      }
      return await Promise.all(promises)
    },
    async addTerritories () {
      const promises = [] 
      // split coma-separated territories
      let territories = this.territoriesUpdated.split(',').map((t) => t.trim())
      // check for added territory
      for (let k in territories) {
        if (territories[k] === '') { continue; }
        if (!this.territoriesOriginal.includes(territories[k])) {
          promises.push(this.postCoverage(territories[k]))
        }
      }
      return await Promise.allSettled(promises)
    },
    async removeTerritories (){
      const promises = [] 
      // split coma-separated territories
      let territories = this.territoriesUpdated.split(',').map((t) => t.trim())
      // check for removed territories
      for (let k1 in this.territoriesOriginalRaw) {
        if (!territories.includes(this.territoriesOriginalRaw[k1].postalCode)) {
          // remove territory
          promises.push(this.$store.dispatch('deleteCoverage', this.territoriesOriginalRaw[k1].id))
          // for (let k2 in this.territoriesOriginalRaw) {
          //   if (this.territoriesOriginalRaw[k2].postalCode == this.territoriesOriginal[k1]) {
          //     await this.$store.dispatch('deleteCoverage', this.territoriesOriginalRaw[k2]['id'])
          //   }
          // }
        }
      }
      // this.loadBusiness()
      return await Promise.allSettled(promises)
    },
    async postCoverage (territory) {
      let tData = {}
      tData.postalCode = territory
      tData.business = "/api/businesses/" + this.businessId
      // get lat,lng from the Google
      return await this.$geocoder.send({ zip_code: territory }, async response => {
        if (response.results.length) {
          let location = response.results[0].geometry.location
          tData.lat = location.lat.toString()
          tData.lng = location.lng.toString()
        }
        // add new territory
        return await this.$store.dispatch('postCoverage', tData)
      })
    },
    resetBusinessData () {
      /* Reset our form values */
      this.businessId = null
      this.selectedUsers = []
      this.territoriesOriginalRaw =[]
      this.territoriesOriginal = []
      this.territoriesUpdated = ''
      this.businessForm = { ...FORM_DEFAULTS }
    },
    onReset (evt) {
      evt.preventDefault()
      this.resetBusinessData()
      /* Trick to reset/clear native browser form validation state */
      this.show = false
      this.$nextTick(() => { this.show = true })
    }
  },
  watch: {
    '$route' () {
      if (this.$route.query.bid) {
        this.businessId = this.$route.query.bid
        this.loadBusiness()
      } else {
        this.resetBusinessData()
        this.loadBusiness()
      }
    },
    'businessForm.areaName' () {
      this.setUriName()
    },
    selectedUsers (val) {
      if (val && val.length === 1){
        this.businessForm.primaryContact = val[0].value
      }
    },
    'businessForm.primaryContact' () {
      let customEmail = true
      let customAddress = true
      for (let i in this.userList) {
        if (this.userList[i].email === this.businessForm.email) {
          customEmail = false
        }
        if (this.userList[i].address1 === this.businessForm.address1) {
          customAddress = false
        }
      }
      
      for (let i in this.userList) {
        if (this.userList[i]['@id'] == this.businessForm.primaryContact) {
          (! customEmail) ? this.businessForm.email = this.userList[i].email : null
          if (! customAddress) {
            this.businessForm.address1 = this.userList[i].address1
            this.businessForm.address2 = this.userList[i].address2
            this.businessForm.city = this.userList[i].city
            this.businessForm.state = this.userList[i].state
            this.businessForm.zipCode = this.userList[i].zipCode
          }
        }
      }
    }
  },
  created() {
    this.businessForm = { ...FORM_DEFAULTS }
    this.$store.dispatch('getUsers', {
      roles: 'ROLE_DIRECTOR', 
      active: 1,
      pagination: false
    }).then(() => {
      this.directorOptions = this.userList.map((u) =>  ({ label: u.fullName, value: u['@id'] }))
    }).then(() => {
      if (this.$route.query.view === 'public-data') {
        this.iniSave = true
      }
      if (this.$route.query.bid) {
        this.businessId = this.$route.query.bid
        this.loadBusiness()
      }
      if (this.$route.params.bid) {
        this.businessId = this.$route.params.bid
        this.loadBusiness()
      }
      if (this.$route.query.uid) {
        let userId = IRI_USERS.replace('_id', this.$route.query.uid)
        for (let i in this.directorOptions) {
          if (userId === this.directorOptions[i].value) {
            this.selectedUsers.push(this.directorOptions[i])
          }
        }
      }
    })
  },
  destroyed() {
    this.$store.dispatch('resetBusiness')
    this.$store.dispatch('resetUsers')
  }
}
</script>
