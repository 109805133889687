// global.js
export const WORKING = 'WORKING'
export const SPORTS_DATA = 'SPORTS_DATA'
export const SPORT_PROGRAM = 'SPORT_PROGRAM'
export const MEDIA_DATA = 'MEDIA_DATA'
// events.js
export const SET_EVENTS = 'SET_EVENTS'
export const ADD_EVENT = 'ADD_EVENT'
export const SET_EVENT_PRICING = 'SET_EVENT_PRICING'
export const ADD_EVENT_PRICING = 'ADD_EVENT_PRICING'
export const SET_EVENT_LOCATIONS = 'SET_EVENT_LOCATIONS'
export const ADD_EVENT_LOCATION = 'ADD_EVENT_LOCATION'
export const SET_EVENT_TYPES = 'SET_EVENT_TYPES'
export const ADD_EVENT_TYPE = 'ADD_EVENT_LOCATION'
export const SET_EVENT_STATUSES = 'SET_EVENT_STATUSES'
export const ADD_EVENT_STATUS = 'ADD_EVENT_STATUS'
export const SET_EVENT_QUESTIONS = 'SET_EVENT_QUESTIONS'
export const ADD_EVENT_QUESTION = 'ADD_EVENT_QUESTION'
export const SET_EVENT_REGISTRATIONS = 'SET_EVENT_REGISTRATIONS'
export const ADD_EVENT_REGISTRATION = 'ADD_EVENT_REGISTRATION'
export const SET_EVENT_GROUPS = 'SET_EVENT_GROUPS'
export const ADD_EVENT_GROUP = 'ADD_EVENT_GROUP'
export const SET_EVENT_RECORDS = 'SET_EVENT_RECORDS'
export const ADD_EVENT_RECORD = 'ADD_EVENT_RECORD'
export const SET_EVENT_SCHEDULES = 'SET_EVENT_SCHEDULES'
export const ADD_EVENT_SCHEDULE = 'ADD_EVENT_SCHEDULE'
// business.js
export const BUSINESSES_DATA = 'BUSINESSES_DATA'
export const BUSINESS_DATA = 'BUSINESS_DATA'
export const BUSINESS_USER_DATA = 'BUSINESS_USER_DATA'
export const BUSINESS_USERS_DATA = 'BUSINESS_USERS_DATA'
export const SET_DISCOUNT_CODES = 'SET_DISCOUNT_CODES'
export const ADD_DISCOUNT_CODE = 'ADD_DISCOUNT_CODE'
export const SET_INVOICES = 'SET_INVOICES'
export const ADD_INVOICE = 'ADD_INVOICE'
export const SET_PAYMENT_OPTIONS = 'SET_PAYMENT_OPTIONS'
export const ADD_PAYMENT_OPTION = 'ADD_PAYMENT_OPTION'
export const SET_BUSINESS_PAYMENT_OPTIONS = 'SET_BUSINESS_PAYMENT_OPTIONS'
export const ADD_BUSINESS_PAYMENT_OPTION = 'ADD_BUSINESS_PAYMENT_OPTION'
export const SET_BUSINESS_SETTING_OPTIONS = 'SET_BUSINESS_SETTING_OPTIONS'
export const SET_BUSINESS_SETTINGS = 'SET_BUSINESS_SETTINGS'
//content.js
export const SET_CONTENT = 'SET_CONTENT'
export const ADD_CONTENT = 'ADD_CONTENT'
// currentUser.js
export const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGOUT = 'LOGOUT'
export const CURRENT_USER_DATA = 'CURRENT_USER_DATA'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const SET_ORIGINAL_USER = 'SET_ORIGINAL_USER'
// marketingSources.js
export const SET_MARKETING_SOURCE = 'SET_MARKETING_SOURCE'
export const SET_MARKETING_SOURCES = 'SET_MARKETING_SOURCES'
export const ADD_MARKETING_SOURCE = 'ADD_MARKETING_SOURCE'
// pages.js
export const PAGES_DATA = 'PAGES_DATA'
export const PAGE_DATA = 'PAGE_DATA'
export const SET_FEATURED_IMAGES = 'SET_FEATURED_IMAGES'
export const ADD_FEATURED_IMAGE = 'ADD_FEATURED_IMAGE'
// participants.js
export const ADD_PARTICIPANT = 'ADD_PARTICIPANT'
export const SET_PARTICIPANTS = 'SET_PARTICIPANTS'
export const ADD_PARTICIPANT_RELATIONSHIP = 'ADD_PARTICIPANT_RELATIONSHIP'
export const SET_PARTICIPANT_RELATIONSHIPS = 'SET_PARTICIPANT_RELATIONSHIPS'
export const ADD_USER_RELATIONSHIP = 'ADD_USER_RELATIONSHIP'
export const SET_USER_RELATIONSHIPS = 'SET_USER_RELATIONSHIPS'
// reports.js
export const SET_REPORT_REVENUE = 'SET_REPORT_REVENUE'
export const SET_REPORTS_REVENUE = 'SET_REPORTS_REVENUE'
export const ADD_REPORT_REVENUE = 'ADD_REPORT_REVENUE'
export const SET_REPORT_MARKETING = 'SET_REPORT_MARKETING'
export const SET_REPORTS_MARKETING = 'SET_REPORTS_MARKETING'
export const ADD_REPORT_MARKETING = 'ADD_REPORT_MARKETING'
// sponsors.js
export const ADD_SPONSOR = 'ADD_SPONSOR'
export const SET_SPONSORS = 'SET_SPONSORS'
export const ADD_SPONSOR_CAMPAIGN = 'ADD_SPONSOR_CAMPAIGN'
export const SET_SPONSOR_CAMPAIGNS = 'SET_SPONSOR_CAMPAIGNS'
// supportMedia.js
export const SUPPORT_MEDIA_DATA = 'SUPPORT_MEDIA_DATA'
export const SUPPORT_CATEGORIES = 'SUPPORT_CATEGORIES'
// users.js
export const USER_DATA = 'USER_DATA'
export const USERS_DATA = 'USERS_DATA'
// message.js
export const SET_MESSAGES = 'SET_MESSAGES'
export const ADD_MESSAGE = 'ADD_MESSAGE'
// survey.js
export const SET_SURVEY = 'SET_SURVEY'
export const SET_SURVEY_RESPONSE = 'SET_SURVEY_RESPONSE'
export const SET_SURVEY_QUESTION_RESPONSES = 'SET_SURVEY_QUESTION_RESPONSES'